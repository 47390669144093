.side-button {
  display: inline-block;
  width: 5em;
  height: 2.5em;
  line-height: 2.5em;
  cursor: pointer;
  background: #fffce1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8em;
  font-weight: 800;
}
.sidebar-nav-item{
  cursor: pointer;
}
.sidebar-nav-item2{
  border-top: gray 1px solid;
}
.sidebar-nav-item3{
  border-bottom: gray 1px solid;
}
.side-button.left {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.side-button.right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.side-button.active {
  background: #c94e50;
  color: #fffce1;
}
@media screen and (max-width: 40em) {
  main {
    font-size: 80%;
  }
  h1 {
    padding-top: 1em;
    font-size: 2.5em;
  }
  .demo-buttons {
    max-width: 900px;
    padding: 0 2em;
  }
}
.bm-burger-button {
  position: relative;
  width: 20px;
  height: 12px;
  left: 30px;
  top: 140px;
}
.right .bm-burger-button {
  left: initial;
  right: 36px;
}
.bm-burger-bars {
  background:gray;
}
.bm-morph-shape {
  fill: #373a47;
}
.bm-menu-wrap {
  top:88px;
}
.bm-menu {
  background:whitesmoke;
}
.bm-menu a {
  color: #b8b7ad;
}
.bm-menu a:hover,
.bm-menu a:focus {
  color: #c94e50;
}
.bm-item-list a {
  padding: 0.8em;
}
.bm-item-list a span {
  margin-left: 10px;
  font-weight: 700;
}
.bm-item:focus {
  outline: none;
}
.menu-1 .bm-cross {
  background: #bdc3c7;
}
.menu-1 .bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}
.menu-2 .bm-cross {
  background: #999;
}
.menu-2 .bm-menu {
  padding: 3.4em 1em 0;
}
.menu-2 a {
  padding: 1em;
}
.menu-2 i {
  font-size: 1.7em;
  vertical-align: middle;
  color: #282a35;
}
.menu-3 .bm-cross {
  background: #888;
}
.menu-3 .bm-menu {
  padding: 2em 1em;
  font-size: 1.15em;
}
.menu-3 i {
  opacity: 0.5;
}
.menu-3 span {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.75em;
}
.menu-4 .bm-cross {
  background: #888;
}
.menu-4 h2 {
  margin: 0 auto;
  padding: 2em 1em;
  color: rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}
.menu-4 h2 i {
  margin-left: 0.2em;
}
.menu-4 h2 span {
  font-size: 1.6em;
  font-weight: 700;
}
.menu-4 a {
  padding: 1em;
  text-transform: uppercase;
  transition: background 0.3s, box-shadow 0.3s;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}
.menu-4 a span {
  letter-spacing: 1px;
  font-weight: 400;
}
.menu-4 a:hover,
.menu-4 a:focus {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
  color: #b8b7ad;
}
#slide .bm-cross,
#stack .bm-cross,
#reveal .bm-cross {
  background: #bdc3c7;
}
#slide .bm-menu,
#stack .bm-menu,
#reveal .bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #8cc63f;
}